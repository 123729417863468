import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h4>{`Unity Game Engine & Front-End Developer - Junior High School Undergraduate.`}</h4>
    <p>{`Since the beginning of my journey as a programmer nearly 4 years ago, I've learned unity game development allowing me to build hundreds of games, then I followed up by learning front-end web and python development. I'm quite confident and incredibly curious, Soon I'll start collaborating with talented people to create new games and websites, and someday, maybe someday I'll be an incredible programmer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      