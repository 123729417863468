import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Send An `}<a parentName="p" {...{
        "href": "mailto:contact@maximusadeola.com"
      }}>{`Email At contact@maximusadeola.com `}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://github.com/MaxDevYt"
      }}>{`Github`}</a>{` & `}<a parentName="p" {...{
        "href": "https://discord.gg/YHe3cXKtZx"
      }}>{`Discord`}</a></p>
    <p>{`See My `}<a parentName="p" {...{
        "href": "https://resume.io/r/tty6Fm47P"
      }}>{`Resume`}</a>{` Here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      