import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Github Profile" link="https://github.com/MaxDevYt" bg="linear-gradient(to right, #04f45c 0%, #00fff2 100%)" mdxType="ProjectCard">
This is my Entire Github Profile Cointaing Almost all of my Small and Big Projects.
    </ProjectCard>
    <ProjectCard title="Apps" link="https://play.google.com/store/apps/developer?id=MaxxDev" bg="linear-gradient(to right, #e281fc 0%, #00fff2 100%)" mdxType="ProjectCard">
This is all The Games I've Ever Created and Uploaded On The Play Store
    </ProjectCard>
    <ProjectCard title="Socket.io CHAT APP" link="https://chat-app-with-react-socketio.netlify.app/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Chat app using React, Socket.io-client, Node, Express, & Socket.io.
    </ProjectCard>
    <ProjectCard title="URL SHORTENER" link="https://www.behance.net/gallery/58937147/Freiheit" bg="linear-gradient(to right, #00944c 0%, #ffec2a 100%)" mdxType="ProjectCard">
A Server Side Rendered URL Shortener which used Node.js, Express.js, EJS, Mongoose, & MongoDB Atlas.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      